import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationSettings } from '../../models/application-settings';
import { ActivatedRoute } from '@angular/router';

import { IMasterDataService } from '../../interfaces/master-data';
import { ServiceFactory } from '../../factory/service-factory';
import { WebApiService } from '../../services/web-api.service';


@Component({
  selector: 'masterdata-dropdown',
  templateUrl: 'masterdata-dropdown.component.html'
})
export class MasterdataDropdownComponent implements OnInit {
  private htmlCleanRegex = /<[^>]*>/g;
  _auctionClusterId: number;
  _masterDataListId: number;
  _filteredKeys: Array<number> = [];
  _fieldIds: Array<number> = [];

  masterDataService: IMasterDataService;

  @ViewChild('masterDataDropdown') dropDown; 
  @Output() onValueChanged = new EventEmitter();
  @Output() onInitialized = new EventEmitter();
  @Output() onFocusOut = new EventEmitter();
  @Output() onFocusIn = new EventEmitter();

  @Input("name") name: string;
  @Input() selectedRowId: number;
  @Output() selectedRowIdChange = new EventEmitter<number>();

  @Input('required') required: boolean;
  @Input('disabled') disabled: boolean;
  @Input('tabIndex') tabIndex: number;
  @Input('auctionClusterId') set auctionClusterId(value: number) {
    this._auctionClusterId = value;
    this.setDataSource();
  }
  @Input('listId') set masterDataListId(value: number) {
    this._masterDataListId = value;
    this.setDataSource();
  }
  @Input('filteredKeys') set filteredKeys(value: Array<number>) {
    this._filteredKeys = value;
    this.setDataSource();
  }

  @Input('fieldIds') set fieldIds(value: Array<number>) {
    this._fieldIds = value;
    this.setDataSource();
  }
  @Input('fieldId') set fieldId(value: number) {
    this._fieldIds = [value];
    this.setDataSource();
  }

  get instance(): any {
    return this.dropDown.instance;
  }
  dataSource: DataSource;
  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;

  constructor(private translateService: TranslateService,
        protected appSettings: ApplicationSettings,
        protected route: ActivatedRoute,
        protected webApiService: WebApiService
  ) 
  { 
    this.masterDataService = ServiceFactory.getMasterDataService(document.location.hash, route, appSettings, webApiService);
  }

  ngOnInit(): void {
    this.onInitialized.emit();
  }

  setDataSource() {
    if (this._masterDataListId != undefined && this._masterDataListId > 0 && this._auctionClusterId != undefined && this._auctionClusterId > 0) {
      this.dataSource = new DataSource({
        paginate: true,
        pageSize: 20,
        store: new CustomStore({
          key: 'value',
          load: async (loadOptions) => {
            var result = await this.masterDataService.getMasterDataListForDropdown(this._masterDataListId, this._auctionClusterId, this.translateService.currentLang, this._filteredKeys, this._fieldIds, loadOptions).toPromise();
            result.data.forEach(item => {
              item.label = item.label.replace(this.htmlCleanRegex, '');
            })
            return result;
          },
          byKey: async (key) => {
            var result = await this.masterDataService.getMasterDataValueForDropdown(this._masterDataListId, this._auctionClusterId, this.translateService.currentLang, key, this._fieldIds).toPromise();
            if (result.data?.length > 0) {
              result.data[0].label = result.data[0].label.replace(this.htmlCleanRegex, '');
              // we need to reselect the focused text after it changes
              let el = <any>document.activeElement;
              if (el && el.select)
                el.select();
              return result.data[0];
            }
            return null;
          }
        })
      });
    }
  }

  valueChanged(e) {
    this.selectedRowIdChange.emit(this.selectedRowId);
    this.onValueChanged.emit(e);
  }

  focusOut(e) {
    this.onFocusOut.emit(e);
  }

  focusIn(e) {
    this.onFocusIn.emit(e);
  }
}
