import { Injectable } from '@angular/core';
import { Observable, of  } from 'rxjs';

// interfaces
import { IMasterDataService } from '../../../shared/interfaces/master-data';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { MasterData, MasterDataListField } from '../../../shared/models/master-data';

// services
import { WebApiService } from '../../../shared/services/web-api.service';


@Injectable()
export class MasterDataService implements IMasterDataService {
  masterDataLists: Array<MasterData> = [];
  masterDataListFields: Array<MasterDataListField> = [];

  private apiPath: string;
  private supplierId: number;

  cachedLabels = [];

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'supplierlevel';
  }

  setId(id: number){
    this.supplierId = id;
  }

  getMasterDataList(masterDataListId: number, supplierId: number, auctionClusterId: number): Observable<MasterData> {
    const url = this.apiPath + '/' + supplierId + '/auctioncluster/' + auctionClusterId + '/masterdatalist/' + masterDataListId;
    return this.webApiService.getList(url);
  }

  //IMasterDataService 

  getMasterDataForReports(auctionClusterId: number, masterDataListId: number, includeRows: boolean): Observable<MasterData> {
    var url = this.apiPath + '/' + this.supplierId + '/auctioncluster/' + auctionClusterId + '/masterdatalist/' + masterDataListId ;
    if (includeRows) {
      url += '/reports';
    }
    else {
      url += '/withoutrows';
    }
    return this.webApiService.getList(url);
  }

  getMasterDataListByIdsTouchOnly(masterDataListIds: Array<number>, auctionClusterId: number): Observable<Array<MasterData>> {
    var url = this.apiPath + '/' + this.supplierId + '/auctioncluster/' + auctionClusterId + '/masterdatalist/list';
    return this.webApiService.save(url, masterDataListIds);
  }  

  getMasterDataListForDropdown(masterDataListId: number,
      auctionClusterId: number,
      language: string,
      keys: Array<number>,
      fieldIds: Array<number>,
      loadOptions: any) {
      const url = `${this.apiPath}/${this.supplierId}/auctioncluster/${auctionClusterId}/masterdatalist/${masterDataListId}/dropdown`;
      let queryString = `?language=${language}`;
      if (keys != null && keys.length > 0) {
        queryString += `&keys=${keys.join(',')}`;
      }
      if (fieldIds != null && fieldIds.length > 0) {
        queryString += `&masterdatalistFieldIds=${fieldIds.join(',')}`;
      }
  
      var params = this.webApiService.getParamsFromLoadOptions(loadOptions);
      let result = this.webApiService.getWithParams(url + queryString, params);
      return result;
    }
  
    getMasterDataValueForDropdown(masterDataListId: number,
      auctionClusterId: number,
      language: string,
      key: number,
      fieldIds: Array<number>) {
      if (!this.cachedLabels[key]) {
        const url = `${this.apiPath}/${this.supplierId}/auctioncluster/${auctionClusterId}/masterdatalist/${masterDataListId}/dropdown?language=${language}&keys=${key}`;
        let queryString = `?language=${language}&keys=${key}`;
        if (fieldIds != null && fieldIds.length > 0) {
          queryString += `&masterdatalistFieldIds=${fieldIds.join(',')}`;
        }
  
        let result = this.webApiService.getSingle(url + queryString);
        result.subscribe(l => this.cachedLabels[key] = l.data);
        return result;
      }
      else {
        // return cached data as observable
        return of(
          {
            data: this.cachedLabels[key]
          });
      }
    }
}
