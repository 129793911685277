<div class="auction-supply-monitor-component item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons">
    <dx-button hint="{{ 'SHARED.PRINT' | translate }}"
               icon="print"
               (onClick)="printGrid()"></dx-button>
  </div>

  <div class="card">
    <div class="card-header">
      {{ 'SUPPLY_MONITOR.FILTER_LOTS' | translate }}
    </div>

    <div>
      <div class="filter-column">
        <label for="catalogs">{{ 'SHARED.CATALOGS' | translate }}</label>
        <dx-select-box name="catalog"
                       [items]="catalogs"
                       valueExpr="catalogId"
                       [(value)]="catalogId"
                       displayExpr="name"
                       [rtlEnabled]="rtlEnabled"
                       [searchEnabled]="true"
                       [showClearButton]="false"
                       placeholder="{{ 'SHARED.SELECT' | translate }} ..."
                       cssClass="filter-lot-select">
        </dx-select-box>
      </div>

      <div class="filter-column">
        <label>{{ 'SHARED.DATE' | translate }}</label>
        <label for="date" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
          <long-date-component class="dt-input"
                               [name]="date"
                               [data]="date"
                               [required]="true"
                               (modelChanged)="dateChanged($event)">
          </long-date-component>
        </label>
      </div>
    </div>

    <div>
      <div *ngFor="let filter of filters; let i = index" class="filter-column">
        <!-- Date -->
        <div *ngIf="filter.propertyTypeId == 4">
          <label>{{ getTranslation(filter.name) }}</label>
          <dx-select-box name="filter.{{filter.name}}"
                         [items]="dateFilterItems"
                         valueExpr="value"
                         [value]="getDateValue(filter.productPropertyId)"
                         displayExpr="name"
                         [rtlEnabled]="rtlEnabled"
                         [searchEnabled]="true"
                         [showClearButton]="true"
                         placeholder="{{ 'SHARED.SELECT' | translate }} ..."
                         cssClass="filter-lot-select"
                         (onValueChanged)="clearDatePeriod(filter.productPropertyId); setValue(filter.productPropertyId, $event)">
          </dx-select-box>
          <div class="date-range-holder" *ngIf="dateFiltersCascadingDefinitions[filter.productPropertyId] == '3'">
            <dx-date-box type="date"
                         displayFormat="shortdate"
                         openOnFieldClick="true"
                         [value]="dateValue1(filter.productPropertyId)"
                         (onValueChanged)="handleDate1ValueChange(filter.productPropertyId, $event)">
            </dx-date-box>
            <dx-date-box type="date"
                         openOnFieldClick="true"
                         [disabled]="isDisabled(filter.productPropertyId)"
                         displayFormat="shortdate"
                         [min]="getMin(filter.productPropertyId)"
                         [value]="dateValue2(filter.productPropertyId)"
                         (onValueChanged)="handleDate2ValueChange(filter.productPropertyId, $event)">
            </dx-date-box>
          </div>
        </div>
        <!-- Master data value -->
        <div *ngIf="filter.propertyTypeId == 5">
          <label>
            {{ getTranslation(filter.name) }}
          </label>
          <dx-select-box name="product{{i}}"
                         [items]="prepareData(i)"
                         valueExpr="value"
                         [(value)]="filterBindings[filter.productPropertyId]"
                         [displayExpr]="translateSelectBoxes"
                         [rtlEnabled]="rtlEnabled"
                         [searchEnabled]="true"
                         [showClearButton]="true"
                         placeholder="{{ 'SHARED.SELECT' | translate }} ..."
                         (onValueChanged)="onPropertyChanged(filter); clearLotPropertyValue(filter.productPropertyId, $event)"
                         cssClass="filter-lot-select">
          </dx-select-box>
        </div>
      </div>
      <div class="filter-column" *ngIf="filters.length !== 0">
        <label></label>
        <button type="button" class="btn btn-icon btn-primary" (click)="openSaveAsNewUserReportTemplateDialog()">
          <i class="dx-icon-plus"></i>{{ 'SHARED.SAVE_AS' | translate }}
        </button>
      </div>
      <div class="filter-column" *ngIf="filters.length !== 0">
        <label></label>
        <button type="button" class="btn btn-icon btn-primary" (click)="showLoadReportProfile()">
          <i class="dx-icon-refresh"></i>{{ 'REPORTING.USER_REPORT_TEMPLATE.LOAD_TEMPLATE' | translate }}
        </button>
      </div>
    </div>

    <div>
      <div class="filter-column">
        <div class="filter-button">
          <button type="button" class="btn btn-primary" [disabled]="catalogId === null" (click)="filterLots()">{{ 'SUPPLY_MONITOR.FILTER_LOTS' | translate }}</button>
        </div>
      </div>
      <div class="last-refresh-column">
        <span *ngIf="lastRefresh !== ''">
          {{ 'SUPPLY_MATRIX.LAST_REFRESH' | translate }} {{ lastRefresh }}
        </span>
      </div>
    </div>
  </div>

  <dx-data-grid #auctionSupplierMonitorGrid
                id="printSection"
                [rtlEnabled]="rtlEnabled"
                [showBorders]="true"
                (onInitialized)="initColumn($event)"
                (onExporting)="onExporting($event)"
                [allowColumnReordering]="true"
                [hoverStateEnabled]="true"
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true">
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true"> </dxo-search-panel>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="{{ 'storage_supply_monitor_auction_' + auctionClusterId + selectedLanguage}}"></dxo-state-storing>
    <dxo-pager [visible]="true"
               [showPageSizeSelector]="true"
               [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
               [showNavigationButtons]="true"
               [showInfo]="true"
               infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}"></dxo-pager>
    <dxi-column type="buttons"
               [width]="120"
               [fixed]="true"
               fixedPosition="right"
               cellTemplate='buttonsTemplate'></dxi-column>
                  <div *dxTemplate="let d of 'buttonsTemplate'">
                    <ng-container *ngIf="buyBackPresaleButtonVisible">
                      <a (click)="edit(d.data.__lotId__, d)" class="dx-link" ><i title="{{ 'SUPPLY_MONITOR.OPEN_BUYBACK' | translate }}" class="dx-icon-detailslayout"></i></a>
                    </ng-container>
                    </div>
    <div *dxTemplate="let cell of 'cellTemplate'">
      <span [innerHtml]="cell.text | format | safeHtml"></span>
    </div>
    <div *dxTemplate="let cell of 'textCellTemplate'">
      <span [innerHtml]="cell.text | safeHtml"></span>
    </div>
    <div *dxTemplate="let data of 'imageCellTemplate'" class="image-column">
      <img *ngIf="doesImageCalculationExist(data.value)" [src]="getFirstDefinedImage(data.value)" class="thumbnail-image"
        (click)="showImageCarrousel(data.value);" />
    </div>
  </dx-data-grid>

  <dx-popup [width]="800" [height]="'auto'" [closeOnOutsideClick]="true" [showTitle]="true"
    title="{{ 'SUPPLY_MONITOR.SHOW_CARROUSEL' | translate }}" [dragEnabled]="false" [showCloseButton]="true"
            [(visible)]="popUpShowed" (onHiding)="closePopUp();">
    <div class="au-dx-popup-padder">
      <dx-gallery [dataSource]="carrouselData" [showIndicator]="carrouselData.length > 1" [showNavButtons]="true" [width]="'auto'" [height]="'auto'">
        <div *dxTemplate="let galleryItem of 'item'">
          <img (load)="openPopUp();" [src]="galleryItem.field_0" />
          <div>{{ getTranslation(galleryItem.field_1) }}</div>
        </div>
      </dx-gallery>
      </div>
  </dx-popup>

  <dx-popup #saveAuctionSupplyMonitorPopupForm
            width="600"
            height="250"
            [showTitle]="true"
            title="{{ 'REPORTING.USER_REPORT_TEMPLATE.TITLE' | translate }}"
            [dragEnabled]="false"
            [closeOnOutsideClick]="false"
            [rtlEnabled]="rtlEnabled"
            [(visible)]="isSaveProfileDialogOpened">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <dx-form #saveReportingForm
                 id="saveReportingForm"
                 [showValidationSummary]="false"
                 colCount="auto"
                 [minColWidth]="500">
        <dxi-item label="{{ 'SHARED.NAME' | translate }}" dataField="newReportName">
            <div *dxTemplate>
              <dx-text-box [(value)]="newReportName"></dx-text-box>
            </div>
          <dxi-validation-rule type="required" message="{{ 'SHARED.NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
          </dxi-item>
        </dx-form>
        <br /><br />
        <button type="submit" class="btn btn-primary" (click)="saveNewReport();" name="btnBuyerSaveTemplateSubmit" [disabled]="isDisabledButton()">
        {{ 'SHARED.SAVE' | translate }}
        </button>
      <button type="button" class="btn btn-outline" (click)="closeSaveAsNewUserReportTemplateDialog();">{{ 'SHARED.CANCEL' | translate }}</button>
        </div>
    </dx-scroll-view>
  </dx-popup>


  <dx-popup #loadAuctionSupplyMonitorPopupForm
            width="600"
            height="250"
            [showTitle]="true"
            title="{{ 'REPORTING.USER_REPORT_TEMPLATE.LOAD_TEMPLATE' | translate }}"
            [dragEnabled]="false"
            [closeOnOutsideClick]="false"
            [rtlEnabled]="rtlEnabled"
            [(visible)]="isLoadProfileDialogOpened">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <dx-form #loadReportingForm
                 id="loadReportingForm"
                 [showValidationSummary]="false"
                 colCount="auto"
                 [minColWidth]="500">
          <dxi-item dataField="selectReporting" [template]="'selectReportingTemplate'">
            <dxo-label text="{{ 'REPORTING.USER_REPORT_TEMPLATE.MANAGER_TITLE' | translate }}"></dxo-label>
          </dxi-item>
          <div *dxTemplate="let data of 'selectReportingTemplate'">
            <dx-select-box [items]="allReports"
                           valueExpr="reportId"
                           displayExpr="description"
                           [(value)]="reportId"
                           [showClearButton]="true"
                           searchEnabled="true"></dx-select-box>
          </div>
        </dx-form>
        <br /><br />
            <button type="submit" class="btn btn-primary" (click)="reportTemplateChange();" [disabled]="isDisabledLoadButton()">
              {{ 'SHARED.SELECT' | translate }}
            </button>
      <button type="button" class="btn btn-outline" (click)="closeLoadReportProfile();">{{ 'SHARED.CANCEL' | translate }}</button>
        </div>
    </dx-scroll-view>
  </dx-popup>
</div>

<buyback-on-lot-component #details (onClosed)="onDetailsComponentClosed($event)"></buyback-on-lot-component>
