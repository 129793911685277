<div class="ticket-printer-component item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup [width]="600"
            [showTitle]="true"
            title="{{ modalTitle }}"
            [dragEnabled]="false"
            [closeOnOutsideClick]="false"
            [rtlEnabled]="rtlEnabled"
            [(visible)]="isOpened"
            (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <dx-form #ticketPrinterForm
               id="ticketPrinterForm"
               [showValidationSummary]="false"
               (onFieldDataChanged)="onFieldDataChanged($event)"
               colCount="auto"
               [(formData)]="model"
               [minColWidth]="500">
        <dxi-item itemType="tabbed">
          <dxi-tab title="{{ 'SHARED.GENERAL' | translate }}">
            <dxi-item dataField="name">
              <dxo-label text="{{ 'SHARED.NAME' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'SHARED.NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="routingSubKey">
              <dxo-label text="{{ 'TICKET_PRINTER.ROUTINGSUBKEY' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'TICKET_PRINTER.ROUTINGSUBKEY' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="ticketType"
                      editorType="dxSelectBox"
                      [editorOptions]="{ items: ticketPrinterTypes, valueExpr:'value', displayExpr:'name', searchEnabled: 'true', showClearButton: 'true'}">
              <dxo-label text="{{ 'SHARED.TYPE' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'SHARED.TYPE' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="enabled" editorType="dxCheckBox">
              <dxo-label text="{{ 'SHARED.ENABLED' | translate }}" location="right"></dxo-label>
            </dxi-item>
            <dxi-item dataField="useAsSystemFieldPrinter" editorType="dxCheckBox">
              <dxo-label text="{{ 'TICKET_PRINTER.USE_AS_SYSTEMFIELD_PRINTER' | translate }}" location="right"></dxo-label>
            </dxi-item>
            <dxi-item dataField="useAllLocations" editorType="dxCheckBox">
              <dxo-label text="{{ 'TICKET_PRINTER.USE_ALL_LOCATIONS' | translate }}" location="right"></dxo-label>
            </dxi-item>
            <dxi-item dataField="locations" [(visible)]="!model.useAllLocations" [template]="'locationsTemplate'">
              <dxo-label text="{{ 'TICKET_PRINTER.LOCATIONS' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item dataField="clocks" [template]="'clocksTemplate'">
              <dxo-label text="{{ 'SHARED.CLOCKS' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item dataField="catalogs" [template]="'catalogsTemplate'">
              <dxo-label text="{{ 'SHARED.CATALOGS' | translate }}"></dxo-label>
            </dxi-item>

            <dxi-item dataField="status" [template]="'statusTemplate'">
              <dxo-label text="{{ 'TICKET_PRINTER.STATUS' | translate }}"></dxo-label>
            </dxi-item>
          </dxi-tab>
          <dxi-tab title="{{ 'TICKET_PRINTER.ADVANCED' | translate }}">
            <dxi-item dataField="printTiming"
                      editorType="dxSelectBox"
                      [editorOptions]="{ items: printTimingTypes, valueExpr:'value', displayExpr:'name', searchEnabled: 'true', showClearButton: 'true'}">
              <dxo-label text="{{ 'TICKET_PRINTER.PRINT_TIMING' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'TICKET_PRINTER.PRINT_TIMING' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
            </dxi-item>               
            <dxi-item *ngIf="model.printTiming === 2" dataField="printingDelay" editorType="dxNumberBox">
              <dxo-label text="{{ 'TICKET_PRINTER.PRINTING_DELAY' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'TICKET_PRINTER.PRINTING_DELAY' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item *ngIf="model.printTiming === 3" dataField="printingOnIOId" editorType="dxNumberBox">
              <dxo-label text="{{ 'TICKET_PRINTER.PRINTING_ON_IO_ID' | translate }}"></dxo-label>
            </dxi-item>
        </dxi-tab>
        </dxi-item>

        <div class="au-dx-popup-padder">
          <div *dxTemplate="let data of 'locationsTemplate'">
            <dx-tag-box [dataSource]="locations"
                        valueExpr="locationId"
                        displayExpr="name"
                        searchEnabled="true"
                        [(value)]="model.locations">
            </dx-tag-box>
          </div>
          <div *dxTemplate="let data of 'clocksTemplate'">
            <dx-tag-box [dataSource]="clocks"
                        valueExpr="clockId"
                        displayExpr="name"
                        searchEnabled="true"
                        [(value)]="model.clocks">
            </dx-tag-box>
          </div>
          <div *dxTemplate="let data of 'catalogsTemplate'">
            <dx-tag-box [dataSource]="catalogs"
                        valueExpr="catalogId"
                        displayExpr="name"
                        searchEnabled="true"
                        [(value)]="model.catalogs">
            </dx-tag-box>
          </div>
          <div *dxTemplate="let data of 'statusTemplate'">
            <dx-button stylingMode="text" icon="isnotblank" *ngIf="isDisconnected(model.status)" id="isDisconnectedIcon"></dx-button>
            <dx-button stylingMode="text" icon="isnotblank" *ngIf="isConnected(model.status)" id="isConnectedIcon"></dx-button>
            <dx-button stylingMode="text" icon="isnotblank" *ngIf="isError(model.status)" id="isErrorIcon"></dx-button>
            {{ translateStatus(model.status) }}
          </div>
        </div>
      </dx-form>
      <br />
<div class="au-dx-popup-padder">
        <button type="button" class="btn btn-primary" (click)="onTestTicket();" name="btnTestTicket" disabled="disabled">{{ 'SHARED.TEST' | translate }}</button>            
        <button type="button" class="btn btn-primary" (click)="clearBuffer()">{{ 'TICKET_PRINTER.CLEAR_BUFFER' | translate }}</button>
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnTicketPrinterSubmit" disabled="disabled">
          {{ 'SHARED.SAVE' | translate }}
        </button>
        <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
        </div>
    </dx-scroll-view>
  </dx-popup>
</div>
