/**
 * rounds js Date to year/month/day and returns this as UTC, ignoring the original timezone.
 * @param date file content as a Blob
 */
export const roundToUTCDate = (date: Date = null) => {
  if (date == null) {
    date = new Date();
  }
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};
