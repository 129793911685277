import { Component, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { DateTimeAdapter, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { Subscription } from 'rxjs';
import * as _moment from 'moment';

// components
import { ReportComponent } from '../../shared/components/reporting/report.component';

// models
import { ProductPropertyTypeEnum } from '../../shared/enum/productPropertyTypeEnum';
import { ApplicationSettings } from '../../shared/models/application-settings';

// services
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../shared/services/auth.service';
import { BuyerService } from '../../shared/services/buyer.service';
import { ErrorService } from '../../shared/services/error.service';
import { LanguageService } from '../../shared/services/language.service';
import { SpinnerService } from '../../shared/services/spinner.service';
import { TitleService } from '../../shared/services/title.service';
import { WebApiService } from '../../shared/services/web-api.service';


const moment = (_moment as any).default ? (_moment as any).default : _moment;
const NOON = 12;

@Component({
  selector: 'report-editor.component',
  templateUrl: 'report-editor.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['report-editor.component.scss'],
  providers: [{ provide: DateTimeAdapter, useClass: MomentDateTimeAdapter }, { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB' }]
})

export class ReportEditorComponent extends ReportComponent implements OnDestroy {
  @ViewChild('filterForm') filterForm: NgForm;

  lang = 'nl-be';

  clockId: number;
  userId: number;

  myRouter: Router;
  reportDoc: any;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;


  constructor(
    appSettings: ApplicationSettings,
    route: ActivatedRoute,
    //private productService: ProductService,
    titleService: TitleService,
    languageService: LanguageService,
    router: Router,
    errorService: ErrorService,
    buyerService: BuyerService,
    spinner: SpinnerService,
    translateService: TranslateService,
    dateTimeAdapter: DateTimeAdapter<any>,
    authenticationService: AuthService,
    webApiService: WebApiService) {
    super(appSettings, route, titleService, languageService, router, errorService, buyerService, spinner, translateService, dateTimeAdapter, webApiService, authenticationService);
    route.params.subscribe(val => {
      this.init();
    });

    this.lang = this.translateService.currentLang;
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  init() {
    this.clockStartGuid = this.route.snapshot.queryParams.clockStartGuid !== undefined
      ? this.route.snapshot.queryParams.clockStartGuid
      : '';

    this.date = this.route.snapshot.queryParams.date !== undefined
      ? this.route.snapshot.queryParams.date
      : null;

    this.clockId = this.route.snapshot.queryParams.clockId !== undefined
      ? this.route.snapshot.queryParams.clockId
      : 0;

    this.userId = this.route.snapshot.queryParams.userId !== undefined
      ? this.route.snapshot.queryParams.userId
      : 0;

    super.init();
  }

  SpecificHandlingAfterFetchingServices(): void {
    if (this.clockStartGuid || (this.userId && this.clockId)) {
      this.buildReportUrl(false);
    }
  }

  getFilterForServerFixedFiltering(filterForServer) {
    if (this.systemReportDesignType === 1) {
      filterForServer.push({
        productPropertyTypeId: ProductPropertyTypeEnum.TEXT,
        productPropertyId: null,
        dateRangeType: null,
        key: 'ClockStartGuid',
        value: this.clockStartGuid.toString(),
        entityId: null
      });
      filterForServer.push({
        productPropertyTypeId: ProductPropertyTypeEnum.DATE,
        productPropertyId: null,
        dateRangeType: null,
        key: 'SALESDATE',
        value: this.date,
        entityId: null
      });
    }

    if (this.systemReportDesignType === 2) {
      filterForServer.push({
        productPropertyTypeId: ProductPropertyTypeEnum.NUMBER,
        productPropertyId: null,
        dateRangeType: null,
        key: 'ClockId',
        value: this.clockId.toString(),
        entityId: null
      });
      filterForServer.push({
        productPropertyTypeId: ProductPropertyTypeEnum.NUMBER,
        productPropertyId: null,
        dateRangeType: null,
        key: 'UserId',
        value: this.userId.toString(),
        entityId: null
      });
    }
  }

  navigate(report) {
    this.router.navigate(['/auction/reports/' + this.auctionClusterId + '/report/' + report.reportId]);
  }
}

