<div class="auction-transaction-monitor-component item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }"
     dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons">
    <dx-button hint="{{ 'SHARED.PRINT' | translate }}" icon="print" (onClick)="print()"></dx-button>
  </div>

  <div class="card">
    <div class="card-header">
      {{ 'TRANSACTION_MONITOR.FILTER_TRANSACTIONS' | translate }}
    </div>
    <div class="filter-column">
      <label for="catalogs">{{ 'SHARED.CATALOGS' | translate }}</label>
      <dx-select-box name="catalog" [items]="catalogs" valueExpr="catalogId" [(value)]="catalogId" displayExpr="name"
                     [rtlEnabled]="rtlEnabled" [searchEnabled]="false" [showClearButton]="false"
                     placeholder="{{ 'SHARED.SELECT' | translate }} ..." cssClass="filter-lot-select">
      </dx-select-box>
    </div>

    <div class="filter-column">
      <label>{{ 'SHARED.DATE' | translate }}</label>
      <label for="date" aria-haspopup="true" role="tooltip"
             class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
        <long-date-component class="dt-input" [name]="date" [data]="date" [required]="true"
                             (modelChanged)="dateChanged($event)">
        </long-date-component>
      </label>
    </div>

    <div>

      <div class="filter-column">
        <div class="filter-button">
          <button type="button" class="btn btn-primary"
                  (click)="filterLots()">
            {{ 'TRANSACTION_MONITOR.FILTER_TRANSACTIONS' | translate }}
          </button>
          <button [disabled]="!anyRowSelected(false)" type="button" class="btn btn-primary"
                  (click)="printTransactions()">
            {{ 'TRANSACTION_MONITOR.PRINT_TRANSACTIONS' | translate }}
          </button>
          <button *ngIf="user.isSystemUser" [disabled]="!anyRowSelected(true)" type="button" class="btn btn-primary"
                  (click)="resendTransactions()">
            {{ 'TRANSACTION_MONITOR.RESEND' | translate }}
          </button>
          <button type="button" class="btn btn-primary"
                  (click)="sendNotification($event)">
            {{ 'SHARED.SEND_NOTIFICATION' | translate }}
          </button>
          <span *ngIf="lastTimeSentNotification">{{ 'TRANSACTION_MONITOR.LAST_SENT_NOTIFICATION' | translate }} {{lastTimeSentNotification}}</span>
        </div>
      </div>
    </div>
  </div>


  <dx-data-grid #transactionMonitorGrid id="printSection" [rtlEnabled]="rtlEnabled" [showBorders]="true"
                (onInitialized)="initColumn($event)"
                [allowColumnReordering]="true"
                [hoverStateEnabled]="true"
                [columnAutoWidth]="true"
                [rowAlternationEnabled]="true"
                [(selectedRowKeys)]="selectedRows"
                (onSelectionChanged)="selectionChangedHandler($event)"
                (onRowPrepared)="editRowProperties($event)"
                (onRowUpdating)="updateTransaction($event)"
                (onExporting)="onExporting($event)">
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-selection selectAllMode="page" showCheckBoxesMode="always" mode="multiple"></dxo-selection>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
               [showNavigationButtons]="true" [showInfo]="true"
               infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}"></dxo-pager>
    <dxo-editing mode="popup" [allowUpdating]="true" [allowDeleting]="false"></dxo-editing>
    <div *dxTemplate="let cell of 'cellTemplate'">
      <span [innerHtml]="cell.text | format | safeHtml"></span>
    </div>
    <div *dxTemplate="let cell of 'textCellTemplate'">
      <span [innerHtml]="cell.text | safeHtml"></span>
    </div>
    <dxi-column minWidth="0" [visible]="statusVisible()" caption="Status" dataField="" [calculateCellValue]="confirmationStatusText"></dxi-column>
    <dxi-column type="buttons" minWidth="0" [visible]="buttonsVisible()" >
      <dxi-button [visible]="isTransactionNotAutoCreated" hint="{{ 'TRANSACTION_MONITOR.OPENREPORT' | translate }}" icon="chart" [onClick]="openReport"></dxi-button>
      <dxi-button [visible]="canEditTransaction" hint="{{ 'TRANSACTION_MONITOR.EDITTRANSACTION' | translate }}" icon="edit" [onClick]="openEditor"></dxi-button>
      <dxi-button [visible]="canAskForConfirmation" hint="{{ 'TRANSACTION_MONITOR.ASKFORCONFIRMATION' | translate }}" icon="check" [onClick]="askConfirmation"></dxi-button>
      <dxi-button [visible]="isAwaitingConfirmation" hint="{{ 'TRANSACTION_MONITOR.AWAITINGCONFIRMATION' | translate }}" icon="clock" [onClick]="cancelConfirmation"></dxi-button>
      <dxi-button [visible]="canRejectTransaction" hint="{{ 'PRODUCT.PROPERTY.REJECTBEHAVIOR_MARKASUNSOLD' | translate }}" cssClass="rejectButton" icon="close" [onClick]="rejectTransaction"></dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>

<auction-transaction-detail-component #details (onClosed)="onDetailsComponentClosed($event)"></auction-transaction-detail-component>
