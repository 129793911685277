<div class="gallery-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="item-list-component">
    <div class="row">
      <div class="filter-column">
        <label for="clockSelect">{{ 'GALLERY.SELECT_AUCTION' | translate }}</label>
        <dx-select-box name="clockSelect"
                       [items]="auctions"
                       displayExpr="name"
                       valueExpr="auctionId"
                       [(value)]="selectedAuction"
                       [rtlEnabled]="rtlEnabled"
                       [searchEnabled]="true"
                       placeholder="{{ 'SHARED.SELECT' | translate }} ..."
                       (onValueChanged)="getSelectedGallerySeats()"
                       (onFocusOut)="getSelectedGallerySeats()">
        </dx-select-box>
      </div>
    </div>

  <dx-data-grid #ardsInfoGrid [rtlEnabled]="rtlEnabled" [dataSource]="ardsInfos"
                [showBorders]="true"
                [hoverStateEnabled]="true"
                [rowAlternationEnabled]="true" *ngIf="hasGallery">
    <dxi-column dataField="clockName" caption="{{ 'Clock' | translate }}">
    </dxi-column>
    <dxi-column dataField="connectionStatus" caption="{{ 'Online Status' | translate }}">
    </dxi-column>
    <dxi-column dataField="version" caption="{{ 'Version' | translate }}">
    </dxi-column>
    <dxi-column dataField="galleryType" caption="{{ 'Gallery Type' | translate }}">
    </dxi-column>
    <dxi-column dataField="gallerySize" caption="{{ 'Gallery Size' | translate }}">
    </dxi-column>
  </dx-data-grid>

    <div class="grid-buttons">
      <dx-button hint="{{ 'SHARED.ADD' | translate }}" *ngIf="!isCTIF" icon="add" (onClick)="add()"></dx-button>
      <dx-button hint="{{ 'SHARED.PRINT' | translate }}" icon="print" (onClick)="print()"></dx-button>
    </div>

    <dx-data-grid #gallerySeatsGrid id="printSection" [rtlEnabled]="rtlEnabled" [dataSource]="items"
                  [showBorders]="true"
                  [allowColumnReordering]="true"
                  [hoverStateEnabled]="true"
                  [columnAutoWidth]="true"
                  [rowAlternationEnabled]="true"
                  (onExporting)="onExporting($event)">
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-selection mode="single"></dxo-selection>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxo-pager [visible]="true"
                 [showPageSizeSelector]="true"
                 [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
                 [showNavigationButtons]="true"
                 [showInfo]="true"
                 infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
      </dxo-pager>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="auctionName" caption="{{ 'AUCTION.TITLE' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" cellTemplate="buyerTemplate" dataField="buyerNumber" caption="{{ 'SHARED.BUYER' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" dataType="number" dataField="seatNumber" caption="{{ 'GALLERY.SEAT_NUMBER' | translate }}">
      </dxi-column>
      <dxi-column [editorOptions]="{ showClearButton: true }" *ngIf="isCTIF" dataType="boolean" dataField="reserved" caption="{{ 'GALLERY.RESERVED' | translate }}">
      </dxi-column>
      <dxi-column type="buttons" [width]="70">
        <dxi-button hint="{{ 'SHARED.EDIT' | translate }}" icon="edit" [onClick]="edit"></dxi-button>
        <dxi-button hint="{{ 'SHARED.DELETE' | translate }}" icon="trash" [onClick]="deleteItem"></dxi-button>
      </dxi-column>
      <div *dxTemplate="let data of 'buyerTemplate'">
        
        <label class="green" *ngIf="data.data.expectedBuyerIsAtReservedSeat">{{ data.data.buyerNumber }}</label>
        <label *ngIf="!data.data.expectedBuyerIsAtReservedSeat && data.data.expectedBuyerNumberAtSeat" class="red">{{ data.data.buyerNumber }}</label>
        <label *ngIf="!data.data.expectedBuyerIsAtReservedSeat && !data.data.expectedBuyerNumberAtSeat">{{ data.data.buyerNumber }}</label>
        <label *ngIf="!data.data.expectedBuyerIsAtReservedSeat && data.data.expectedBuyerNumberAtSeat">, </label>
        <label *ngIf="!data.data.expectedBuyerIsAtReservedSeat && data.data.expectedBuyerNumberAtSeat">{{ data.data.expectedBuyerNumberAtSeat }}</label>
      </div>
    </dx-data-grid>
  </div>

  <gallery-seat-component #details (onClosed)="onDetailsComponentClosed($event)"></gallery-seat-component>
  <confirmation-component #confirmation title="{{ 'GALLERY.DELETE_GALLERY_SEAT' | translate }}"
                          message="{{ 'GALLERY.DELETE_GALLERY_SEAT_MESSAGE' | translate }}"
                          buttonCloseText="{{ 'SHARED.DELETE' | translate }}" cancellable="true" (close)="deleteSelected();"></confirmation-component>
</div>
